.Dartboard__sector, .Dartboard__bull {
  stroke: #d0edfd;
  cursor: pointer;
}
.Dartboard__out {
  cursor: pointer;
}
.Dartboard__sector:hover {
  opacity: 0.7;
}
.Dartboard__out.is-black:hover {
  fill: #070707;
}
.Dartboard__bull:hover {
  transform: scale(1.2);
  transform-origin: center;
}
.Dartboard__board-number {
  cursor: pointer;
  fill: #fff;
  font-family: "Open Sans";
  font-size: 28px;
  user-select: none;
}
.Dartboard__sector.is-black, .Dartboard__out.is-black {
  fill: #000;
}
.Dartboard__sector.is-red, .Dartboard__bull.is-red {
  fill: var(--red);
}
.Dartboard__sector.is-green, .Dartboard__bull.is-green {
  fill: var(--green);
}
.Dartboard__sector.is-beige {
  fill: #e7e4c7;
}