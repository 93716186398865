.Mark {
  height: 100%;
  position: relative;
  box-sizing: border-box;
}
.Mark__bars-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-5%, -50%);
  width: 100%;
  height: 100%;
}
.Mark__first-bar, .Mark__second-bar {
  position: absolute;
  width: 10%;
  height: 100%;
  background-color: grey;
  transform: rotate(45deg);
}
.Mark__second-bar {
  transform: rotate(-45deg);
}
.Mark__circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
  height: 65%;
  border: 4px solid white;
  border-radius: 50%;
}
.Mark__circle.is-big {
  border-width: 7px;
}

.Mark__number {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 2em;
}
.Mark__number.is-bull {
  font-size: 1.5em;
}
