.CurrentRound {
  display: flex;
  flex-direction: column;
}
.CurrentRound__scores-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
.CurrentRound__buttons-container {
  position: absolute;
  left: 0;
}
.CurrentRound__round-title {
}
.CurrentRound__voice-recognition-container {
  position: absolute;
  right: 0;
}
.CurrentRound__button-cancel, .CurrentRound__button-redo {
  width: 100px;
  height: 100px;
  font-size: 2em;
  border-radius: 50%;
  border: 0;
  outline: 0;
  background-color: var(--red);
  color: var(--dark-red);
}
.CurrentRound__button-cancel:hover {
  background-color: var(--dark-red);
  color: var(--red);
}
.CurrentRound__button-cancel:disabled {
  background-color: black;
  color: var(--dark-red);
}
.CurrentRound__button-redo {
  width: 75px;
  height: 75px;
  font-size: 2em;
  background-color: var(--green);
  color: var(--dark-green);
}
.CurrentRound__button-redo:hover {
  background-color: var(--dark-green);
  color: var(--green);
}
.CurrentRound__button-redo:disabled {
  background-color: black;
  color: var(--dark-green);
}
.CurrentRound__player-scores {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 30px;
}
.CurrentRound__round {
  width: 120px;
  height: 120px;
  border: 3px solid black;
  border-radius: 50%;
  font-size: 1.5em;
  text-shadow: 1px 1px black;
}
