.Scores__table {
  border-spacing: 0;
}
.Scores__score-container {}
.Scores__number-col {}
.Scores__score {
  width: 65px;
  border-left: 2px solid black;
}
.Scores__number {
  height: 50px;
  width: 50px;
  font-size: 1.5em;
  border-left: 2px solid black;
}
.Scores__players-container {}
.Scores__player-form {
  height: 50px;
  width: 230px;
  border-top: 2px solid black;
  border-left: 0;
  display: flex;
  align-items: center;
}
.Scores__play {
  margin-right: 8px;
  opacity: 0;
}
.Scores__play.is-playing {
  opacity: 1;
}
.Scores__player-scores {}
.Scores__player-score {
  text-align: center;
}
.Scores__player-mark, .Scores__player-score {
  height: 50px;
  border-top: 2px solid black;
  border-left: 2px solid black;
}
.Scores__add-player-container, .Scores__add-player-colspan {
  border-top: 2px solid black;
  text-align: center;
}
.Scores__add-player-btn {
  padding: 8px 40px;
  background-color: transparent;
  color: white;
  font-size: 1.1em;
  border: 0;
  outline: 0;
}
