.VoiceRecognition__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
}
.VoiceRecognition__micro-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 1.5em;
  border: 0;
  outline: 0;
}
.VoiceRecognition__micro-btn.is-active {
  color: #2196f3;
}
.VoiceRecognition__transcript {
  font-style: normal;
  font-family: monospace;
  width: 200px;
  height: 1.5em;
  padding: 15px;
  overflow: hidden;
  text-align: center;
  color: #aaa;
}
.VoiceRecognition__transcript.is-final {
  color: white;
}