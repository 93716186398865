.App {
  display: flex;
  min-height: 100vh;
  justify-content: space-around;
  align-items: center;
  background-color: var(--dark-grey);
  color: white !important;
  padding: 0 30px;
}

.App__dartboard-container {
  width: 50%;
  padding-right: 30px;
}

.App__scores-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}
