.PlayerForm {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PlayerForm__buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.PlayerForm:hover .PlayerForm__buttons-container {
  opacity: 1;
}
.PlayerForm__input {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: white;
  font-size: 1.2em;
  width: 100%;
}
.PlayerForm__btn-edit, .PlayerForm__btn-delete {
  padding: 8px;
  outline: 0;
  border: 0;
  background-color: transparent;
  color: white;
}
.PlayerForm__btn-delete {
  font-size: 1.2em;
}
.PlayerForm__btn-delete.is-disabled {
  color: black;
}
